<template>
  <v-text-field
    :value="readonly ? tmpValue + ' ' + suffix : tmpValue"
    @click:append-outer="$emit('appendClicked', $event)"
    @blur="$emit('blur', tmpValue)"
    @click:clear="$event.target.blur()"
    @change="handleChange($event, 'change')"
    @input="handleInput($event, 'input')"
    @keypress="disabled ? '' : inputFilter($event)"
    @click:append="$emit('appendInnerClicked', $event)"
    :label="label"
    :suffix="readonly ? '' : suffix"
    :rules="rules"
    :dense="dense"
    :clearable="!disabled && clearable"
    :placeholder="placeholder"
    :disabled="disabled"
    :readonly="readonly"
    hide-spin-buttons
    :hide-details="hideDetails"
    :persistent-placeholder="placeholder != null"
    :append-outer-icon="appendIcon"
    :XXXclear-icon="clearIcon"
    :prepend-icon="prependIcon"
    :append-icon="appendInnerIcon"
  />
</template>

<script lang="js">
import commonMixin from '@/common/js/commonMixin.js';

export default {
  name: 'EditField',
  mixins: [commonMixin],
  props: {
    value: { type: [String, Number], default: null },
    label: { type: String, default: '' },
    suffix: { type: String, default: '' },
    placeholder: { type: String, default: null },
    rules: { type: Array, default: () => { return []; } },
    dense: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    appendIcon: { type: String, default: null },
    hideDetails: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    digits: { type: Number, default: null },
    clearIcon: { type: String, default: null },
    prependIcon: { type: String, default: '' },
    appendInnerIcon: { type: String, default: '' }
  },
  data () {
    return {
      tmpValue: null
    };
  },
  // computed: {
  //   content () {
  //     return this.tmpValue;
  //   }
  // },
  watch: {
    value () {
      this.formatToString(this.value);
    }

  },
  mounted () {
    this.formatToString(this.value);
  },
  methods: {
    formatToString (v) {
      if (v != null) {
        switch (this.type) {
          case 'percent':
          case 'float':
          case 'int':
          case 'upercent':
          case 'ufloat':
          case 'uint':
            v = this.$filters.formatNumber(v, this.digits, this.NULLVALUE);
            break;
          case 'text':
          default: v = v.toString().trim();
            break;
        }
      }
      this.tmpValue = v;
      return v;
    },
    handleInput (e, emit = null) {
      let v = e; // this.$filters.formatInput(e, this.type);
      this.$emit(emit, v);
    },
    handleChange (e, emit = null) {
      let v = this.$filters.formatInput(e, this.type);
      this.handleInput(v, 'input');
      this.formatToString(v);
      this.$emit(emit, v);
    },
    inputFilter (e) {
      e = e || window.event;
      let regex = null;
      let expect = e.target.value.toString() + e.key.toString();
      switch (this.type) {
        case 'percent':
        case 'float': regex = /^[-+]?[0-9]*,?[0-9]*$/; break;
        case 'int': regex = /^[-+]?[0-9]*$/; break;
        case 'upercent':
        case 'ufloat': regex = /^[0-9]*,?[0-9]*$/; break;
        case 'uint': regex = /^[0-9]*$/; break;
        case 'text':
        default: return true;
      }

      if (!regex.test(expect)) e.preventDefault();
      else return true;
    }

  }
};
</script>

<style scoped>
</style>
