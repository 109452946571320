
/* *****************************************************
 * defaultUserRights
***************************************************** */
export const defaultUserRights = Object.freeze({
  readBaseData: { allowed: true },
  manageBaseData: { allowed: false },
  readResidualValues: { allowed: true },
  manageResidualValues: { allowed: false },
  examineResidualValues: { allowed: false },
  activateResidualValues: { allowed: false },
  uploadResidualValues: { allowed: false },
  readCondition: { allowed: true },
  manageCondition: { allowed: false },
  examineCondition: { allowed: false },
  activateCondition: { allowed: false },
  uploadCondition: { allowed: false }
});

/* *****************************************************
 * defaultOrgSettings
***************************************************** */
export const defaultOrgSettings = Object.freeze({
  showBaseData: true,
  showConditions: true,
  showResidualValues: true,
  showXlsImport: false,
  publishing: {
    examiners: 1, // 0-2
    activatorRole: false,
    examinerNotWriter: false
  },
  useInternalDealerList: true,
  useInterestScale: false,
  useConditionKeyVariable: false,
  useInterestType: false,
  showAdditionalProducts: false,
  showPartnerOfTheWeek: false,
  rvModificationInRvGroups: false,
  rvClassesInTable: false,
  passTroughDataToChildren: false,
  childrenCreateOwnData: true,
  useCustomizedComponents: false,
  useCustomizedModuleSettings: false,
  customShortname: null
});
