<template>
  <v-container v-if="tmpSettings != null">
    <v-row>
      <v-col
        cols="12"
        lg="3"
        class="pb-0"
      >
        <v-label>{{ $t('condAdmin.orgSettings.availableFunctions') }}</v-label>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        lg="3"
      >
        <v-checkbox
          v-model="tmpSettings.showBaseData"
          :label="$t('condAdmin.orgSettings.showBaseData')"
        ></v-checkbox>
        <v-checkbox
          v-if="tmpSettings.showConditions === true"
          v-model="tmpSettings.useInternalDealerList"
          :label="$t('condAdmin.orgSettings.useInternalDealerList')"
          class="ml-8 mt-2"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        lg="3"
      >
        <v-checkbox
          v-model="tmpSettings.showConditions"
          :label="$t('condAdmin.orgSettings.showConditions')"
          @change="checkAdditionalProducts($event)"
        ></v-checkbox>
        <v-checkbox
          v-if="tmpSettings.showConditions === true"
          v-model="tmpSettings.showAdditionalProducts"
          :label="$t('condAdmin.orgSettings.showAdditionalProducts')"
          class="ml-8 mt-2"
        ></v-checkbox>
        <v-checkbox
          v-if="tmpSettings.showConditions === true"
          v-model="tmpSettings.showPartnerOfTheWeek"
          :label="$t('condAdmin.orgSettings.showPartnerOfTheWeek')"
          class="ml-8 mt-2"
        ></v-checkbox>
        <v-checkbox
          v-if="tmpSettings.showConditions === true"
          v-model="tmpSettings.useInterestScale"
          :label="$t('condAdmin.orgSettings.useInterestScale')"
          class="ml-8 mt-2"
        ></v-checkbox>
        <v-checkbox
          v-if="tmpSettings.showConditions === true"
          v-model="tmpSettings.useConditionKeyVariable"
          :label="$t('condAdmin.orgSettings.useConditionKeyVariable')"
          class="ml-8 mt-2"
        ></v-checkbox>
        <v-checkbox
          v-if="tmpSettings.showConditions === true"
          v-model="tmpSettings.useInterestType"
          :label="$t('condAdmin.orgSettings.useInterestType')"
          class="ml-8 mt-2"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        lg="3"
      >
        <v-checkbox
          v-model="tmpSettings.showResidualValues"
          :label="$t('condAdmin.orgSettings.showResidualValues')"
        ></v-checkbox>
        <v-checkbox
          v-if="tmpSettings.showResidualValues === true"
          v-model="tmpSettings.rvModificationInRvGroups"
          :label="$t('condAdmin.orgSettings.rvModificationInRvGroups')"
          class="ml-8 mt-2"
        ></v-checkbox>
        <v-checkbox
          v-if="tmpSettings.showResidualValues === true && tmpSettings.rvModificationInRvGroups === true"
          v-model="tmpSettings.rvClassesInTable"
          :label="$t('condAdmin.orgSettings.rvClassesInTable')"
          class="ml-8"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        lg="3"
      >
        <v-checkbox
          v-model="tmpSettings.showXlsImport"
          :label="$t('condAdmin.orgSettings.showXlsImport')"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        lg="2"
      >
        <v-label>{{ $t('condAdmin.orgSettings.publishingProcess') }}</v-label>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        lg="6"
        class="pt-0"
      >
        <v-select
          v-model="tmpSettings.publishing.examiners"
          :items="examinerList"
          item-text="text"
          item-value="id"
          :label="$t('condAdmin.orgSettings.examiners')"
        ></v-select>
        <v-checkbox
          v-if="tmpSettings.publishing.examiners > 0"
          v-model="tmpSettings.publishing.examinerNotWriter"
          :label="$t('condAdmin.orgSettings.examinerNotWriter')"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <v-checkbox
          v-model="tmpSettings.publishing.activatorRole"
          :label="$t('condAdmin.orgSettings.activatorRole')"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        lg="2"
      >
        <v-label>{{ $t('condAdmin.orgSettings.dataPassTrough') }}</v-label>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-checkbox
          v-model="tmpSettings.passTroughDataToChildren"
          :label="$t('condAdmin.orgSettings.passTroughDataToChildren')"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-checkbox
          :disabled="tmpSettings.passTroughDataToChildren === false"
          v-model="tmpSettings.childrenCreateOwnData"
          :label="$t('condAdmin.orgSettings.childrenCreateOwnData')"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        lg="2"
      >
        <v-label>{{ $t('condAdmin.orgSettings.customizing') }}</v-label>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        lg="6"
        class="pt-0"
      >
        <v-checkbox
          v-model="tmpSettings.useCustomizedComponents"
          :label="$t('condAdmin.orgSettings.useCustomizedComponents')"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="tmpSettings.useCustomizedComponents === true">
      <v-col
        cols="12"
        lg="6"
      >
        <EditField
          :value="tmpSettings.customShortname"
          :label="$t('condAdmin.orgSettings.customShortName')"
          @input="setShortname($event)"
          clearable
          dense
          hideDetails
        />
      </v-col>
      <v-col
        cols="12"
        lg="6"
        class="pt-0"
      >
        <v-checkbox
          v-model="tmpSettings.useCustomizedModuleSettings"
          :label="$t('condAdmin.orgSettings.useCustomizedModuleSettings')"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import { defaultOrgSettings } from '@/condAdmin/js/condDefaults.js';
import EditField from '@/common/EditField';
import deepmerge from '@/base/js/Deepmerge.js';

export default {
  name: 'condAdminOrgSettings',
  components: {
    EditField
  },
  props: {
    orgSettings: { type: Object, required: true },
    targetOrgShortName: { type: String, required: true }
  },
  data () {
    return {
      tmpSettings: null,
      examinerList: [
        { id: 0, text: this.$t('condAdmin.orgSettings.examinerText0') },
        { id: 1, text: this.$t('condAdmin.orgSettings.examinerText1') },
        { id: 2, text: this.$t('condAdmin.orgSettings.examinerText2') }
      ]
    };
  },
  computed: {
  },
  watch: {
    orgSettings () {
      this.init();
    },
    'tmpSettings.passTroughDataToChildren' () {
      if (this.tmpSettings.passTroughDataToChildren !== true) this.tmpSettings.childrenCreateOwnData = true;
      // else this.tmpSettings.childrenCreateOwnData = false;
    },
    'tmpSettings.useCustomizedComponents' () {
      if (this.tmpSettings && this.tmpSettings.useCustomizedComponents === false) this.tmpSettings.customShortname = this.targetOrgShortName;
    },
    'tmpSettings.showResidualValues' () {
      if (this.tmpSettings.showResidualValues === false) this.tmpSettings.rvModificationInRvGroups = false;
    },
    'tmpSettings.rvModificationInRvGroups' () {
      if (this.tmpSettings.rvModificationInRvGroups === false) this.tmpSettings.rvClassesInTable = false;
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      this.tmpSettings = deepmerge(defaultOrgSettings, this.orgSettings);
      if (this.tmpSettings.customShortname == null) this.tmpSettings.customShortname = this.targetOrgShortName;
      // auto-react to changed settings-Structure
      if (this.tmpSettings.useCustomizedComponents !== true &&
        (this.tmpSettings.customConditionCommissionSettings === true || this.tmpSettings.customConditionDealerSettings === true)
      ) {
        this.tmpSettings.useCustomizedComponents = true;
      }
      delete this.tmpSettings.customConditionCommissionSettings;
      delete this.tmpSettings.customConditionDealerSettings;
    },
    getOrgSettings () {
      return this.tmpSettings;
    },
    setShortname (name) {
      this.tmpSettings.customShortname = name || this.targetOrgShortName;
    },
    checkAdditionalProducts (value) {
      if (value === false) {
        this.tmpSettings.showAdditionalProducts = false;
      }
    }
  }

};
</script>

<style scoped>
</style>
